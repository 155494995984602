import React, { useEffect, useState } from 'react';
import DeleteTestButton from './DeleteTestButton';
import { Link } from 'react-router-dom';
import client from '../api/client';
import { useAlert } from '../context/AlertContext';
import getTests from '../queries/getTests';
import formatDate from '../utils/formatDate';
import Spinner from './Global/Spinner';
export default function Tests() {
	const [tests, setTests] = useState([]);
	const { setAlert } = useAlert();
	const [loading, setLoading] = useState(false);

	const fetchTests = async () => {
		try {
			setLoading(true);
			const testData = await getTests();

			if (testData) {
				setTests(testData);
			}
			setLoading(false);
			return;
		} catch (error) {
			setLoading(false);
			console.error(error);
			setAlert('error', 'An error occurred while fetching tests');
			return;
		}
	};
	useEffect(() => {
		fetchTests();
	}, []);

	const runTest = async (e, id) => {
		console.log({ id });
		setLoading(true);
		try {
			const results = await client.post('/run-test', { testId: id });
			setLoading(false);
			console.log({ results });
		} catch (error) {
			console.error(error);
			setLoading(false);
			return setAlert('error', 'An error occurred while running test');
		}
	};
	useEffect(() => {
		getTests();
	}, []);
	if (loading) return <Spinner />;
	return (
		<div>
			<h1>Tests</h1>

			{tests &&
				tests.reverse().map((test, i) => (
					<div
						className='flex justify-between align-center mt-3 hover:bg-slate-50 p-3'
						key={i}
					>
						<div>
							<p className=''>{test.title}</p>
							<p className='font-thin'>{test.website.url}</p>
							<p className='font-thin'>
								{/* Scan Count: {test.website.scans.length} */}
							</p>
							<p className='font-thin'>
								Last Scan:{' '}
								{test.website.scans.length === 0
									? 'Never'
									: `${formatDate(test.lastScanAt)}`}
							</p>
						</div>
						<div className='space-x-3'>
							<Link to={`/edit-test/${test._id}`}>
								<button className='p-3 rounded shadow bg-aegis-400 text-white'>
									Edit Test
								</button>
							</Link>
							<DeleteTestButton
								setTests={setTests}
								tests={tests}
								test={test}
							/>
							<button
								onClick={(e) => runTest(e, test._id)}
								disabled={loading}
								className='p-3 rounded shadow bg-aegis-400 text-white'
							>
								{loading ? <Spinner /> : 'Run Test'}
							</button>
						</div>
					</div>
				))}
		</div>
	);
}
