import React, { createContext, useContext, useState, useEffect } from 'react';
import client from '../api/client';
import { useAlert } from './AlertContext';
import getTests from '../queries/getTests';
import { useUser } from './UserContext';
const ScrapeContext = createContext();
const ScrapeProvider = ({ children }) => {
	const { user, isLoggedIn } = useUser();
	const [scanData, setScanData] = useState([]);
	const [tests, setTests] = useState([]);
	const [loading, setLoading] = useState(false);
	const [loadingData, setLoadingData] = useState(false);
	const { setAlert } = useAlert();
	const [activeVendor, setActiveVendor] = useState('');
	const fetchTests = async () => {
		try {
			setLoadingData(true);
			const testData = await getTests();
			if (testData) {
				setTests(testData);
			}
			setLoadingData(false);
			return;
		} catch (error) {
			setLoadingData(false);
			console.error(error);
			setAlert('error', 'An error occurred while fetching tests');
			return;
		}
	};

	const fetchReports = async () => {
		try {
			setLoadingData(true);
			const { data } = await client.get('/scrape/reports');
			console.log({ data });
			setScanData(data);
			setLoadingData(false);
			return;
		} catch (error) {
			setLoadingData(false);
			console.error(error);
			return;
		}
	};

	const fetchScans = async () => {
		try {
			setLoadingData(true);
			const { data } = await client.get('/scrape/scans');
			console.log({ data });
			setScanData(data);
			setLoadingData(false);
			return;
		} catch (error) {
			setLoadingData(false);
			console.error(error);
			return;
		}
	};

	const runScrape = async () => {
		try {
			console.log({ user });
			if (!user || !isLoggedIn) {
				return setAlert('error', 'You must be logged in to run a scan');
			} else {
				setLoading(true);
				const res = await client.get('/scrape');
				if (res) {
					console.log(res);
					setLoading(false);
					return;
				}
			}
		} catch (error) {
			setLoading(false);
			return setAlert('error', error);
		}
		setLoading(false);
	};
	const runVendorScrape = async (vendor) => {
		try {
			console.log({ user });
			if (!user || !isLoggedIn) {
				return setAlert('error', 'You must be logged in to run a scan');
			} else {
				setActiveVendor(vendor);
				setLoading(true);
				const res = await client.post('/scrape/vendor', { vendor });
				if (res) {
					const reportRes = await fetchReports();
					console.log({ res, reportRes });
					setLoading(false);
					setActiveVendor('');

					return;
				}
			}
		} catch (error) {
			setLoading(false);
			return setAlert('error', error);
		}
		setLoading(false);
	};
	if (!children) return console.log('no children');
	return (
		<ScrapeContext.Provider
			value={{
				scanData,
				setScanData,
				fetchScans,
				runScrape,
				loading,
				setLoading,
				fetchReports,
				fetchTests,
				runVendorScrape,
				tests,
				setTests,
				loadingData,
				setLoadingData,
				activeVendor,
				setActiveVendor,
			}}
		>
			{children}
		</ScrapeContext.Provider>
	);
};
export const useScrape = () => useContext(ScrapeContext);
export default ScrapeProvider;
