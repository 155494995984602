import React, { useEffect, useState } from 'react';
import Table from './Table';

import Spinner from './Global/Spinner';
import { useScrape } from '../context/ScrapeContext';
export default function Scans() {
	const { fetchScans, scanData, loadingData } = useScrape();

	useEffect(() => {
		fetchScans();
	}, []);

	if (loadingData) return <Spinner />;
	return (
		<div>
			<Table data={scanData} />
		</div>
	);
}
