import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AlertProvider from './context/AlertContext';
import './index.css';
import ScrapeProvider from './context/ScrapeContext';
import UserProvider from './context/UserContext';
import ModalProvider from './context/ModalContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<AlertProvider>
			<ModalProvider>
				<UserProvider>
					<ScrapeProvider>
						<App />
					</ScrapeProvider>
				</UserProvider>
			</ModalProvider>
		</AlertProvider>
	</React.StrictMode>
);
