import React, { useEffect, useState } from 'react';
import client from '../api/client';
import { useAlert } from '../context/AlertContext';
import { TrashIcon } from '@heroicons/react/20/solid';
import { Switch } from '@headlessui/react';
import classNames from '../utils/classNames';
export default function Settings() {
	const [frequency, setFrequency] = useState('5m');
	const { setAlert } = useAlert();
	const [fields, setFields] = useState([
		{ url: '', username: '', password: '' },
	]);
	const [emailFields, setEmailFields] = useState([{ email: '' }]);
	const [config, setConfig] = useState({
		db: {
			resetAll: false,
		},
		run: {
			supplyPro: false,
			builderTrend: false,
			eci: false,
			maronda: false,
			proCore: false,
			vendorSuite: false,
			sendEmail: false,
			writeFiles: false,
		},
		downloadDir: './downloads',
		puppeteerOptions: {
			slowMo: 0,
			devtools: false,
			headless: true,
			args: [],
		},
	});

	const handleConfigCheckboxChange = (section, field) => {
		setConfig((prevConfig) => ({
			...prevConfig,
			[section]: {
				...prevConfig[section],
				[field]: !prevConfig[section][field],
			},
		}));
	};

	const handleConfigInputChange = (section, field, value) => {
		setConfig((prevConfig) => ({
			...prevConfig,
			[section]: {
				...prevConfig[section],
				[field]: value,
			},
		}));
	};

	const handlePuppeteerArgsChange = (index, value) => {
		setConfig((prevConfig) => ({
			...prevConfig,
			puppeteerOptions: {
				...prevConfig.puppeteerOptions,
				args: prevConfig.puppeteerOptions.args.map((arg, i) =>
					i === index ? value : arg
				),
			},
		}));
	};

	const addPuppeteerArg = () => {
		setConfig((prevConfig) => ({
			...prevConfig,
			puppeteerOptions: {
				...prevConfig.puppeteerOptions,
				args: [...prevConfig.puppeteerOptions.args, ''],
			},
		}));
	};

	const removePuppeteerArg = (index) => {
		setConfig((prevConfig) => ({
			...prevConfig,
			puppeteerOptions: {
				...prevConfig.puppeteerOptions,
				args: prevConfig.puppeteerOptions.args.filter((_, i) => i !== index),
			},
		}));
	};

	const addField = () => {
		setFields([...fields, { url: '', username: '', password: '' }]);
	};

	const removeField = (index) => {
		const newFields = [...fields];
		newFields.splice(index, 1);
		setFields(newFields);
	};

	const addEmailField = () => {
		setEmailFields([...emailFields, { email: '' }]);
	};

	const removeEmailField = (index) => {
		const newFields = [...emailFields];
		newFields.splice(index, 1);
		setEmailFields(newFields);
	};

	const handleInputChange = (index, event) => {
		const name = event.target.name;
		const value = event.target.value;
		const newFields = [...fields];
		newFields[index][name] = value;
		setFields(newFields);
	};

	const handleEmailInputChange = (index, event) => {
		const name = event.target.name;
		const value = event.target.value;
		const newFields = [...emailFields];
		newFields[index][name] = value;
		setEmailFields(newFields);
	};
	const fetchScanConfig = async () => {
		try {
			const { data } = await client.get('/scrape/scan-config');
			if (data) {
				console.log('Fetched data:', data);
				setFrequency(data.config.frequency);
				setFields(data.config.urls || []);
				setConfig(data.config.config || {});

				// Log before setting
				console.log('Fetched emails:', data.config.emails);

				const ef = data.config.emails.map((email) => {
					return { email };
				});
				setEmailFields(ef || []);

				// Log after setting
				console.log('emailFields after setting: ', emailFields);
			}
		} catch (error) {
			console.error('Fetch error:', error);
			setAlert('error', 'Could not fetch scan config.');
		}
	};

	useEffect(() => {
		fetchScanConfig();
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			await client.put('/scrape/scan-config', {
				frequency,
				urls: fields,
				emails: emailFields.map((field) => field.email),
				config,
			});
			setAlert('success', 'Scan updated successfully!');
		} catch (error) {
			setAlert('error', 'Failed to update scan.');
		}
	};
	return (
		<div className='gap-y-10'>
			<div className='flex justify-between align-baseline items-center '>
				<div>
					<h2
						id='payment-details-heading'
						className='text-lg font-medium leading-6 text-gray-900'
					>
						Settings
					</h2>
					<p className='mt-1 text-sm text-gray-500 mb-5'>
						Update the configuration here. <br />
						Please note that updating this form may break the scrape.
					</p>
				</div>
				<button
					type='button'
					onClick={handleSubmit}
					className='inline-flex justify-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900'
				>
					Save
				</button>
			</div>

			<section aria-labelledby='payment-details-heading'>
				<form onSubmit={handleSubmit}>
					<div className='shadow sm:overflow-hidden sm:rounded-md'>
						<div className='bg-white px-4 py-6 sm:p-6'>
							<div className='bg-white px-4 py-6 sm:p-6'>
								<h2 className='text-lg font-medium leading-6 text-gray-900 mt-3'>
									Scan Frequency
								</h2>
								<p className='mt-1 text-sm text-gray-500 mb-5'>
									How often the scan should run
								</p>
								<div className='col-span-4 sm:col-span-2'>
									<label
										htmlFor='frequency'
										className='block text-sm font-medium leading-6 text-gray-900'
									>
										Scan Frequency
									</label>
									<select
										id='frequency'
										value={frequency}
										onChange={(e) => setFrequency(e.target.value)}
										className='mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6'
									>
										<option value='never'>Manually - No automatic jobs</option>
										<option value='now'>Once, Now</option>
										<option value='1m'>Every 1 minute</option>
										<option value='5m'>Every 5 minutes</option>
										<option value='15m'>Every 15 minutes</option>
										<option value='30m'>Every 30 minutes</option>
										<option value='1h'>Every 1 hour</option>
										<option value='12h'>Every 12 hours</option>
										<option value='24h'>Every 24 hours</option>
										<option value='mwf'>Monday, Wednesday, Friday</option>
										<option value='tth'>Tuesday, Thursday</option>
									</select>
								</div>

								<h2 className='text-lg font-medium leading-6 text-gray-900 mt-5'>
									Sites & Credentials
								</h2>
								<p className='mt-1 text-sm text-gray-500 mb-5'>
									Which sites & credentials to be used
								</p>
								{fields.map((field, index) => (
									<div key={index}>
										<div className='mt-6 grid grid-cols-5 gap-3 items-end align-baseline'>
											<div className='col-span-4 sm:col-span-2'>
												<label
													htmlFor={`url-${index}`}
													className='block text-sm font-medium leading-6 text-gray-900'
												>
													URL
												</label>
												<input
													type='text'
													placeholder='https://example.com'
													name='url'
													id={`url-${index}`}
													value={field.url}
													onChange={(e) => handleInputChange(index, e)}
													className='mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6'
												/>
											</div>
											<div className='col-span-4 sm:col-span-1'>
												<label
													htmlFor={`username-${index}`}
													className='block text-sm font-medium leading-6 text-gray-900'
												>
													Username
												</label>
												<input
													type='text'
													name='username'
													id={`username-${index}`}
													value={field.username}
													onChange={(e) => handleInputChange(index, e)}
													autoComplete='username'
													className='mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6'
													placeholder='joe_shmo'
												/>
											</div>
											<div className='col-span-4 sm:col-span-1 relative'>
												<label
													htmlFor={`password-${index}`}
													className='flex items-center text-sm font-medium leading-6 text-gray-900'
												>
													<span>Password</span>
												</label>
												<input
													type='password'
													name='password'
													id={`password-${index}`}
													value={field.password}
													placeholder='password'
													onChange={(e) => handleInputChange(index, e)}
													className='mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6'
												/>
											</div>
											<button
												type='button'
												onClick={() => removeField(index)}
												className='mt-2 ml-4 py-1.5 border border-transparent rounded-md text-white bg-red-600 hover:bg-red-700'
											>
												<TrashIcon className='w-5 h-5  text-sm font-medium ' />
											</button>
										</div>
									</div>
								))}

								<button
									type='button'
									onClick={addField}
									className='mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-800'
								>
									Add
								</button>
								<h2 className='text-lg font-medium leading-6 text-gray-900 mt-5'>
									Email Recipients
								</h2>
								<p className='mt-1 text-sm text-gray-500 mb-5'>
									Which emails to send the report to
								</p>
								{emailFields.map((field, index) => (
									<div key={index}>
										<div className='mt-6 grid grid-cols-4 gap-6'>
											<div className='col-span-4 sm:col-span-2'>
												<label
													htmlFor={`email-${index}`}
													className='block text-sm font-medium leading-6 text-gray-900'
												>
													Recipient {index + 1}
												</label>
												<input
													type='email'
													placeholder='test@gmail.com'
													name='email' // changed from url
													id={`email-${index}`}
													value={field.email} // changed from field
													onChange={(e) => handleEmailInputChange(index, e)}
													className='mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6'
												/>
											</div>
											<button
												type='button'
												onClick={() => removeEmailField(index)}
												className='mt-2 ml-4 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700'
											>
												Remove
											</button>
										</div>
									</div>
								))}
							</div>
							<button
								type='button'
								onClick={addEmailField}
								className='mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-800'
							>
								Add
							</button>
						</div>
						{/* <section className='ml-4'>
						<h3 className='text-lg font-medium leading-6 text-gray-900 mt-3'>
							Database Settings
						</h3>
						<label>
							Reset DB
							<input
								type='checkbox'
								className='h-4 w-4 rounded border-gray-300 text-aegis-600 focus:ring-aegis-600 ml-3'
								checked={config.db.reset}
								onChange={() => handleConfigCheckboxChange('db', 'reset')}
							/>
						</label>
						<label>
							Reset All DB
							<input
								type='checkbox'
								className='h-4 w-4 rounded border-gray-300 text-aegis-600 focus:ring-aegis-600 ml-3'
								checked={config.db.resetAll}
								onChange={() => handleConfigCheckboxChange('db', 'resetAll')}
							/>
						</label>

						<h3 className='text-lg font-medium leading-6 text-gray-900 mt-3'>
							Run Settings
						</h3>
			
						{Object.keys(config.run).map((key) => (
							<label
								key={key}
								className='font-light text-gray-900 block text-sm leading-6'
							>
								{key}
								<input
									type='checkbox'
									checked={config.run[key]}
									className='h-4 w-4 rounded border-gray-300 text-aegis-600 focus:ring-aegis-600 ml-3'
									onChange={() => handleConfigCheckboxChange('run', key)}
								/>
							</label>
						))}

						<h3 className='text-lg font-medium leading-6 text-gray-900 mt-3'>
							Puppeteer Options
						</h3>
						<label className='font-light text-gray-900 block text-sm leading-6'>
							Slow Motion
							<input
								type='number'
								className='mt-2 ml-4  w-24 rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6'
								value={config.puppeteerOptions.slowMo}
								onChange={(e) =>
									handleConfigInputChange(
										'puppeteerOptions',
										'slowMo',
										parseInt(e.target.value)
									)
								}
							/>
						</label>
						<label className='font-light text-gray-900 block text-sm leading-6'>
							Devtools
							<input
								type='checkbox'
								checked={config.puppeteerOptions.devtools}
								className='h-4 w-4 rounded border-gray-300 text-aegis-600 focus:ring-aegis-600 ml-3'
								onChange={() =>
									handleConfigCheckboxChange('puppeteerOptions', 'devtools')
								}
							/>
						</label>
						<label className='font-light text-gray-900 block text-sm leading-6'>
							Headless
							<input
								type='checkbox'
								className='h-4 w-4 rounded border-gray-300 text-aegis-600 focus:ring-aegis-600 ml-3'
								checked={config.puppeteerOptions.headless}
								onChange={() =>
									handleConfigCheckboxChange('puppeteerOptions', 'headless')
								}
							/>
						</label>

						<h4 className='text-md font-medium leading-6 text-gray-900 mt-5 inline mr-3'>
							Args
						</h4>
						<button
							onClick={addPuppeteerArg}
							className='bg-aegis-500 text-white py-1 px-3 rounded'
						>
							Add Arg
						</button>
						{config.puppeteerOptions.args.map((arg, index) => (
							<div key={`arg-${index}`}>
								<input
									type='text'
									className='mt-2 rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 sm:text-sm sm:leading-6'
									value={arg}
									onChange={(e) =>
										handlePuppeteerArgsChange(index, e.target.value)
									}
								/>
								<button
									onClick={() => removePuppeteerArg(index)}
									className='bg-red-500 text-white py-1 px-3 rounded ml-4'
								>
									Remove
								</button>
							</div>
						))}
			</section> */}
						<div className='bg-gray-50 px-4 py-3 text-right sm:px-6'>
							<button
								type='submit'
								className='inline-flex justify-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900'
							>
								Save
							</button>
						</div>
					</div>
				</form>
			</section>
		</div>
	);
}
