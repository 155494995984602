import React, { useEffect, useState } from 'react';
import client from '../../api/client';
export default function Screenshots() {
	const [screenshots, setScreenshots] = useState([]);

	const getScreenshots = async () => {
		const res = await client.get('/screenshots');
		res.data.forEach((base64Img) => {
			setScreenshots([...screenshots, base64Img]);
		});
	};
	useEffect(() => {
		getScreenshots();
	}, []);
	return (
		<div>
			<h1>Screenshots</h1>
			<ul>
				{screenshots &&
					screenshots.length &&
					screenshots.map((screenshot) => (
						<li key={screenshot}>
							{screenshot ? (
								<img
									src={screenshot}
									alt='Fetched'
								/>
							) : (
								<p>Loading...</p>
							)}
						</li>
					))}
			</ul>
		</div>
	);
}
