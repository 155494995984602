import React, { useState } from 'react';
import client from '../../api/client';
import { useAlert } from '../../context/AlertContext';
import classNames from '../../utils/classNames';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Global/Spinner';
function SupportEmailForm() {
	const [email, setEmail] = useState('');
	const [subject, setSubject] = useState('');
	const [loading, setLoading] = useState(false);
	const { setAlert } = useAlert();
	const [message, setMessage] = useState('');
	const [name, setName] = useState('');
	const navigate = useNavigate();
	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);
			const res = await client.post('/support', {
				name,
				email,
				subject,
				message,
			});
			setLoading(false);
			setAlert('success', 'Email sent!');
			navigate('/support/success', { replace: true });
			return;
		} catch (error) {
			setLoading(false);
			console.error(error);
			setAlert('error', error);
		}
	};

	return (
		<div className='justify-center flex '>
			<form
				onSubmit={handleSubmit}
				className='w-1/2'
			>
				<div>
					<label
						className='mt-2 block text-sm font-medium leading-6 text-gray-900'
						htmlFor='name'
					>
						Name
					</label>
					<input
						type='text'
						id='name'
						value={name}
						onChange={(e) => setName(e.target.value)}
						className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
					/>
				</div>
				<div>
					<label
						className='mt-2 block text-sm font-medium leading-6 text-gray-900'
						htmlFor='email'
					>
						Your Email
					</label>
					<input
						type='email'
						id='email'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
						required
					/>
				</div>
				<div>
					<label
						className='mt-2 block text-sm font-medium leading-6 text-gray-900'
						htmlFor='subject'
					>
						Subject
					</label>
					<input
						type='text'
						id='subject'
						value={subject}
						onChange={(e) => setSubject(e.target.value)}
						className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
						required
					/>
				</div>
				<div>
					<div>
						<label
							htmlFor='message'
							className='mt-2 block text-sm font-medium leading-6 text-gray-900'
						>
							Describe the problem
						</label>
						<div className='mt-2'>
							<textarea
								rows={4}
								name='message'
								id='message'
								onChange={(e) => setMessage(e.target.value)}
								className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
								defaultValue={''}
							/>
						</div>
					</div>
				</div>
				<button
					type='submit'
					disabled={loading}
					className={classNames(
						'mt-2 rounded-md px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
						loading
							? 'bg-gray-600'
							: 'bg-indigo-600 focus-visible:outline-indigo-600 hover:bg-indigo-500'
					)}
				>
					{loading ? <Spinner /> : 'Send'}
				</button>
			</form>
		</div>
	);
}

export default SupportEmailForm;
