import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAlert } from '../../context/AlertContext';
import { useScrape } from '../../context/ScrapeContext';
import { useUser } from '../../context/UserContext';
import LOGO from '../../logo.png';
import setNavbar from '../../setNavbar';

export default function Nav({ sidebarOpen, setSidebarOpen }) {
	const { setAlert } = useAlert();
	const {
		runScrape,
		loading,
		runVendorScrape,
		setScanData,
		scanData,
		activeVendor,
	} = useScrape();
	const { user, isLoggedIn } = useUser();
	const location = useLocation();
	const { authNavigation, vendorNavigation, navigation } = setNavbar({
		user,
		isLoggedIn,
		location,
		loading,
		setAlert,
		runVendorScrape,
		activeVendor,
	});
	function classNames(...classes) {
		return classes.filter(Boolean).join(' ');
	}

	useEffect(() => {
		sessionStorage.getItem('vendor');
	}, []);
	return (
		<div>
			<Transition.Root
				show={sidebarOpen}
				as={Fragment}
			>
				<Dialog
					as='div'
					className='relative lg:hidden'
					onClose={setSidebarOpen}
				>
					<Transition.Child
						as={Fragment}
						enter='transition-opacity ease-linear duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='transition-opacity ease-linear duration-300'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<div className='fixed inset-0 bg-gray-900/80' />
					</Transition.Child>

					<div className='fixed inset-0 flex'>
						<Transition.Child
							as={Fragment}
							enter='transition ease-in-out duration-300 transform'
							enterFrom='-translate-x-full'
							enterTo='translate-x-0'
							leave='transition ease-in-out duration-300 transform'
							leaveFrom='translate-x-0'
							leaveTo='-translate-x-full'
						>
							<Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
								<Transition.Child
									as={Fragment}
									enter='ease-in-out duration-300'
									enterFrom='opacity-0'
									enterTo='opacity-100'
									leave='ease-in-out duration-300'
									leaveFrom='opacity-100'
									leaveTo='opacity-0'
								>
									<div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
										<button
											type='button'
											className='-m-2.5 p-2.5'
											onClick={() => setSidebarOpen(false)}
										>
											<span className='sr-only'>Close sidebar</span>
											<XMarkIcon
												className='h-6 w-6 text-white'
												aria-hidden='true'
											/>
										</button>
									</div>
								</Transition.Child>
								{/* MOBILE NAV */}
								{/* Sidebar component, swap this element with another sidebar if you like */}
								<div className='flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4'>
									<div className='flex h-16 shrink-0 items-center'>
										<Link to='/'>
											<img
												className='h-8 w-auto'
												src={LOGO}
												alt='Aegis Logo'
											/>
										</Link>
									</div>

									<nav className='flex flex-1 flex-col'>
										<ul
											role='list'
											className='flex flex-1 flex-col gap-y-7'
										>
											<div className=''>
												<div
													role='list'
													className='-mx-2 space-y-1'
												>
													{navigation.map((item) => (
														<div
															key={item.name}
															className='w-full'
														>
															{item.oc ? (
																<button
																	onClick={item.oc}
																	disabled={item.disabled || loading || false}
																	className={classNames(
																		item.current
																			? 'bg-gray-50 text-aegis-600'
																			: 'text-gray-700 hover:text-aegis-600 hover:bg-gray-50',
																		'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full'
																	)}
																>
																	<item.icon
																		className={classNames(
																			item.current
																				? 'text-aegis-600'
																				: 'text-gray-400 group-hover:text-aegis-600',
																			'h-6 w-6 shrink-0'
																		)}
																		aria-hidden='true'
																	/>
																	{item.name}
																</button>
															) : (
																<a
																	href={item.href}
																	className={classNames(
																		item.current
																			? 'bg-gray-50 text-aegis-600'
																			: 'text-gray-700 hover:text-aegis-600 hover:bg-gray-50',
																		'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full'
																	)}
																	target={item.target ? item.target : ''}
																>
																	<item.icon
																		className={classNames(
																			item.current
																				? 'text-aegis-600'
																				: 'text-gray-400 group-hover:text-aegis-600',
																			'h-6 w-6 shrink-0'
																		)}
																		aria-hidden='true'
																	/>
																	{item.name}
																</a>
															)}
														</div>
													))}
												</div>
											</div>
											{user && isLoggedIn && (
												<div>
													<div className='text-xs font-semibold leading-6 text-gray-400'>
														{loading
															? `Running scrape for ${activeVendor}`
															: 'Vendor Scrapes'}
													</div>
													<ul
														role='list'
														className='-mx-2 mt-2 space-y-1'
													>
														{vendorNavigation.map((item) => (
															<div key={item.name}>
																{item.oc ? (
																	<button
																		onClick={item.oc}
																		disabled={item.disabled || loading || false}
																		className={classNames(
																			item.current
																				? 'bg-gray-50 text-aegis-600'
																				: 'text-gray-700 hover:text-aegis-600 hover:bg-gray-50',
																			'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full'
																		)}
																	>
																		<item.icon
																			className={classNames(
																				item.current
																					? 'text-aegis-600'
																					: 'text-gray-400 group-hover:text-aegis-600',
																				'h-6 w-6 shrink-0'
																			)}
																			aria-hidden='true'
																		/>
																		{item.name}
																	</button>
																) : (
																	<a
																		target={item.target ? item.target : ''}
																		href={item.href}
																		className={classNames(
																			item.current
																				? 'bg-gray-50 text-aegis-600'
																				: 'text-gray-700 hover:text-aegis-600 hover:bg-gray-50',
																			'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full'
																		)}
																	>
																		<item.icon
																			className={classNames(
																				item.current
																					? 'text-aegis-600'
																					: 'text-gray-400 group-hover:text-aegis-600',
																				'h-6 w-6 shrink-0'
																			)}
																			aria-hidden='true'
																		/>
																		{item.name}
																	</a>
																)}
															</div>
														))}
													</ul>
												</div>
											)}

											<div>
												<div className='text-xs font-semibold leading-6 text-gray-400'>
													Authentication
												</div>
												<ul
													role='list'
													className='-mx-2 mt-2 space-y-1'
												>
													{authNavigation.map((item) => (
														<div key={item.name}>
															{item.oc ? (
																<button
																	onClick={item.oc}
																	disabled={item.disabled || loading || false}
																	className={classNames(
																		item.current
																			? 'bg-gray-50 text-aegis-600'
																			: 'text-gray-700 hover:text-aegis-600 hover:bg-gray-50',
																		'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full'
																	)}
																>
																	<item.icon
																		className={classNames(
																			item.current
																				? 'text-aegis-600'
																				: 'text-gray-400 group-hover:text-aegis-600',
																			'h-6 w-6 shrink-0'
																		)}
																		aria-hidden='true'
																	/>
																	{item.name}
																</button>
															) : (
																<a
																	target={item.target ? item.target : ''}
																	href={item.href}
																	className={classNames(
																		item.current
																			? 'bg-gray-50 text-aegis-600'
																			: 'text-gray-700 hover:text-aegis-600 hover:bg-gray-50',
																		'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full'
																	)}
																>
																	<item.icon
																		className={classNames(
																			item.current
																				? 'text-aegis-600'
																				: 'text-gray-400 group-hover:text-aegis-600',
																			'h-6 w-6 shrink-0'
																		)}
																		aria-hidden='true'
																	/>
																	{item.name}
																</a>
															)}
														</div>
													))}
												</ul>
											</div>
											{/* <li className='mt-auto'>
												<Link
													to='/settings'
													className='group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-aegis-600'
												>
													<Cog6ToothIcon
														className='h-6 w-6 shrink-0 text-gray-400 group-hover:text-aegis-600'
														aria-hidden='true'
													/>
													Settings
												</Link>
											</li> */}
										</ul>
									</nav>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
			{/* DESKTOP SIDEBAR */}
			{/* Static sidebar for desktop */}
			<div className='hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col'>
				<div className='flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4'>
					<div className='flex h-16 shrink-0 items-center'>
						<Link to='/'>
							<img
								className='h-16 mt-5 w-auto'
								src={LOGO}
								alt='Aegis Logo'
							/>
						</Link>
					</div>
					<nav className='flex flex-1 flex-col'>
						<ul
							role='list'
							className='flex flex-1 flex-col gap-y-7'
						>
							<div>
								<ul
									role='list'
									className='-mx-2 space-y-1'
								>
									{navigation.map((item) => {
										if (!item.oc) {
											return (
												<div key={item.name}>
													<Link
														to={item.href}
														disabled={item.disabled || loading || false}
														target={item.target ? item.target : ''}
														className={classNames(
															item.current
																? 'bg-gray-50 text-aegis-600'
																: 'text-gray-700 hover:text-aegis-600 hover:bg-gray-50',
															'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full'
														)}
													>
														<item.icon
															className={classNames(
																item.current
																	? 'text-aegis-600'
																	: 'text-gray-400 group-hover:text-aegis-600',
																'h-6 w-6 shrink-0'
															)}
															aria-hidden='true'
														/>
														{item.name}
													</Link>
												</div>
											);
										} else {
											return (
												<div key={item.name}>
													<button
														onClick={item.oc}
														disabled={item.disabled || loading || false}
														className={classNames(
															item.current
																? 'bg-gray-50 text-aegis-600'
																: 'text-gray-700 hover:text-aegis-600 hover:bg-gray-50',
															'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full'
														)}
													>
														<item.icon
															className={classNames(
																item.current
																	? 'text-aegis-600'
																	: 'text-gray-400 group-hover:text-aegis-600',
																'h-6 w-6 shrink-0'
															)}
															aria-hidden='true'
														/>
														{item.name}
													</button>
												</div>
											);
										}
									})}
								</ul>
							</div>
							{user && isLoggedIn && (
								<div>
									<div className='text-xs font-semibold leading-6 text-gray-400'>
										{loading
											? `Running scan for ${activeVendor}`
											: 'Scan by Vendor'}
									</div>
									<ul
										role='list'
										className='-mx-2 mt-2 space-y-1'
									>
										{vendorNavigation.map((item) => {
											if (!item.oc) {
												return (
													<div key={item.name}>
														<Link
															disabled={item.disabled || loading || false}
															to={item.href}
															target={item.target ? item.target : ''}
															className={classNames(
																item.current
																	? 'bg-gray-50 text-aegis-600'
																	: 'text-gray-700 hover:text-aegis-600 hover:bg-gray-50',
																'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full'
															)}
														>
															<item.icon
																className={classNames(
																	item.current
																		? 'text-aegis-600'
																		: 'text-gray-400 group-hover:text-aegis-600',
																	'h-6 w-6 shrink-0'
																)}
																aria-hidden='true'
															/>
															{item.name}
														</Link>
													</div>
												);
											} else {
												return (
													<div key={item.name}>
														<button
															disabled={item.disabled || loading || false}
															onClick={item.oc}
															className={classNames(
																item.current
																	? 'bg-gray-50 text-aegis-600'
																	: loading
																	? 'text-gray-400'
																	: 'text-gray-700 hover:text-aegis-600 hover:bg-gray-50',
																'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full disabled:text-gray-300 disabled:hover:bg-transparent disabled:hover:text-gray-300 '
															)}
														>
															<item.icon
																className={classNames(
																	item.current
																		? 'text-aegis-600'
																		: loading
																		? 'text-gray-400'
																		: 'text-gray-400 group-hover:text-aegis-600',
																	'h-6 w-6 shrink-0 ',
																	item.disabled
																		? 'group-hover:text-gray-300 group-text-gray-300'
																		: ''
																)}
																aria-hidden='true'
															/>
															{item.name} {item.disabled && ': Disabled'}
														</button>
													</div>
												);
											}
										})}
									</ul>
								</div>
							)}

							{/* <li>
								<div className='text-xs font-semibold leading-6 text-gray-400'>
									Aegis
								</div>
								<ul
									role='list'
									className='-mx-2 mt-2 space-y-1'
								>
									{authNavigation.map((item) => {
										if (!item.oc) {
											return (
												<li key={item.name}>
													<Link
														to={item.href}
														target={item.target ? item.target : ''}
														className={classNames(
															item.current
																? 'bg-gray-50 text-aegis-600'
																: 'text-gray-700 hover:text-aegis-600 hover:bg-gray-50',
															'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full'
														)}
													>
														<item.icon
															className={classNames(
																item.current
																	? 'text-aegis-600'
																	: 'text-gray-400 group-hover:text-aegis-600',
																'h-6 w-6 shrink-0'
															)}
															aria-hidden='true'
														/>
														{item.name}
													</Link>
												</li>
											);
										} else {
											return (
												<li key={item.name}>
													<button
														onClick={item.oc}
														className={classNames(
															item.current
																? 'bg-gray-50 text-aegis-600'
																: 'text-gray-700 hover:text-aegis-600 hover:bg-gray-50',
															'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full'
														)}
													>
														<item.icon
															className={classNames(
																item.current
																	? 'text-aegis-600'
																	: 'text-gray-400 group-hover:text-aegis-600',
																'h-6 w-6 shrink-0'
															)}
															aria-hidden='true'
														/>
														{item.name}
													</button>
												</li>
											);
										}
									})}
								</ul>
							</li> */}
							{/* <li className='mt-auto'>
								<Link
									to='/settings'
									className='group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-aegis-600'
								>
									<Cog6ToothIcon
										className='h-6 w-6 shrink-0 text-gray-400 group-hover:text-aegis-600'
										aria-hidden='true'
									/>
									Settings
								</Link>
							</li> */}
							<div className='mt-auto'>
								{/* <button
									type='button'
									onClick={() => {
										localStorage.removeItem('hideInstructions');
										setAlert('success', 'Warnings Reset');
									}}
									className='group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-aegis-600'
								>
									<ArchiveBoxXMarkIcon
										className='h-6 w-6 shrink-0 text-gray-400 group-hover:text-aegis-600'
										aria-hidden='true'
									/>
									Reset Warnings
								</button> */}
								{authNavigation.map((item) => {
									if (!item.oc) {
										return (
											<li key={item.name}>
												<Link
													to={item.href}
													id={item.name.toLowerCase().replace(' ', '-')}
													target={item.target ? item.target : ''}
													className={classNames(
														item.current
															? 'bg-gray-50 text-aegis-600'
															: loading
															? 'text-gray-700'
															: 'text-gray-700 hover:text-aegis-600 hover:bg-gray-50',
														'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full'
													)}
												>
													<item.icon
														className={classNames(
															item.current
																? 'text-aegis-600'
																: 'text-gray-400 group-hover:text-aegis-600',
															'h-6 w-6 shrink-0'
														)}
														aria-hidden='true'
													/>
													{item.name}
												</Link>
											</li>
										);
									} else {
										return (
											<li key={item.name}>
												<button
													id={item.name.toLowerCase().replace(' ', '-')}
													disabled={item.disabled || loading || false}
													onClick={item.oc}
													className={classNames(
														item.current
															? 'bg-gray-50 text-aegis-600'
															: loading
															? 'text-gray-700'
															: 'text-gray-700 hover:text-aegis-600 hover:bg-gray-50',
														'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full'
													)}
												>
													<item.icon
														className={classNames(
															item.current
																? 'text-aegis-600'
																: loading
																? 'text-gray-400 group-hover:text-aegis-600'
																: 'text-gray-400 group-hover:text-aegis-600',
															'h-6 w-6 shrink-0'
														)}
														aria-hidden='true'
													/>
													{item.name}
												</button>
											</li>
										);
									}
								})}
							</div>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	);
}
