import {
	ArchiveBoxXMarkIcon,
	ArrowLongDownIcon,
	ArrowUpRightIcon,
	BeakerIcon,
	ChartPieIcon,
	CircleStackIcon,
	ClockIcon,
	CloudArrowDownIcon,
	Cog6ToothIcon,
	DocumentPlusIcon,
	EnvelopeIcon,
	HomeIcon,
	RectangleStackIcon,
	RocketLaunchIcon,
	ScaleIcon,
	UserIcon,
	VideoCameraIcon,
	ViewfinderCircleIcon,
	XMarkIcon,
} from '@heroicons/react/24/outline';
import Auth from './utils/auth';
import Spinner from './components/Global/Spinner';

import {
	CogIcon,
	PhotoIcon,
	QuestionMarkCircleIcon,
	UserCircleIcon,
} from '@heroicons/react/20/solid';

function setNavbar({
	user,
	isLoggedIn,
	location,
	loading,
	setAlert,
	runVendorScrape,
	activeVendor,
}) {
	const vendors = [
		'Supply Pro',
		'Builder Trend',
		'ECI',
		'Maronda',
		'ProCore',
		'Vendor Suite',
	];
	let authNavigation = [];
	let vendorNavigation = [];
	const navigation = [
		{
			name: 'Home',
			href: '/',
			icon: HomeIcon,
			current: location.pathname === '/',
		},
		{
			name: 'Export Report Data',
			href: '/report-data',
			icon: CloudArrowDownIcon,
			current: location.pathname === '/report-data',
		},

		// {
		// 	name: loading ? <Spinner /> : 'Run Scrape',
		// 	href: '#',
		// 	oc: runScrape,
		// 	icon: RocketLaunchIcon,
		// 	current: loading,
		// },
	];
	if (user.isAdmin) {
		navigation.push({
			name: 'Go To VendorSuite',
			href: 'https://login.drhorton.com/adfs/ls/?wtrealm=urn%3avendorsuite%3aweb&wctx=WsFedOwinState%3d53AY8p3UR-desnilczrZcJossoXAeCmp6h71GgmtGbKEKVu6X9xAX5uiGB-8H52APqkRBmZIHiMT44IGqU9WszVIujXMLqCHfD9WymH9rkgE_kNyWXbDw5z4uaCy6xCHKJpk2Zj_3ayIY2doIWJ9WA&wa=wsignin1.0#dashboard',
			icon: ArrowUpRightIcon,
			current: false,
			target: '_blank',
		});
	}
	if (!user || !isLoggedIn) {
		authNavigation = [
			{
				name: 'Login/Signup',
				href: '/login',
				icon: UserCircleIcon,
				current:
					location.pathname === '/login' || location.pathname === '/signup',
			},
		];
	} else {
		authNavigation = [
			{
				name: 'Report a Problem',
				href: '/support',
				icon: QuestionMarkCircleIcon,
				current:
					location.pathname === '/login' || location.pathname === '/signup',
			},
			{
				name: 'Logout',
				oc: Auth.logout,
				icon: UserIcon,
				current:
					location.pathname === '/login' || location.pathname === '/signup',
			},
		];

		if (user.isAdmin) {
			vendors.forEach((vendor) => {
				vendorNavigation.push({
					name: loading && activeVendor === vendor ? <Spinner /> : `${vendor}`,
					oc: () => {
						runVendorScrape(vendor);
						setAlert('info', `${vendor} scan started`);
					},
					icon: RocketLaunchIcon,
					current: false,
					disabled: loading,
				});
			});
		} else if (user.readOnly) {
			vendors.forEach((vendor) => {
				vendorNavigation.push({
					name: loading && activeVendor === vendor ? <Spinner /> : `${vendor}`,
					oc: () => {
						console.warn('unable to run scan because user is readOnly');
					},
					icon: RocketLaunchIcon,
					current: false,
					disabled: true,
				});
			});
		}
	}

	if (user && user.email === 'andrewkeiser@gmail.com') {
		navigation.push(
			{
				name: 'Video',
				href: '/video',
				icon: VideoCameraIcon,
				current: location.pathname === '/video',
			},
			{
				name: 'Crons',
				href: '/ak/crons',
				icon: ClockIcon,
				current: location.pathname === '/ak/crons',
			},
			{
				name: 'Screenshots',
				href: '/ak/screenshots',
				icon: PhotoIcon,
				current: location.pathname === '/ak/screenshots',
			}
		);
	}
	if (
		(user && user.email === 'andrewkeiser@gmail.com') ||
		user.email === 'jrclayton2@gmail.com'
	) {
		navigation.push({
			name: 'Support Emails',
			href: '/support-emails',
			icon: EnvelopeIcon,
			current: location.pathname === '/support-emails',
		});
	}
	return { authNavigation, vendorNavigation, navigation };
}
export default setNavbar;
