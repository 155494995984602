import React, { createContext, useContext, useState, useEffect } from 'react';
const ModalContext = createContext();
const ModalProvider = ({ children }) => {
	const [isVisible, setIsVisible] = useState(false);

	const showModal = () => setIsVisible(true);
	const hideModal = () => setIsVisible(false);
	if (!children) return console.log('no children');
	return (
		<ModalContext.Provider value={{ isVisible, showModal, hideModal }}>
			{children}
		</ModalContext.Provider>
	);
};

/**
 * Usage:
 * ```
 * <button onClick={showModal}>Open Modal</button>
	<Modal>
			<p>This is the modal content!</p>
	</Modal>
	```
 * @returns 
 */
export const useModal = () => useContext(ModalContext);
export default ModalProvider;
