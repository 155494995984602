import React from 'react';
import QuarterlyIncome from './charts/QuarterlyIncome';
import CustomTheme from './charts/CustomTheme';

export default function Dashboard() {
	return (
		<div className='grid grid-cols-10'>
			<div className='shadow p-2 rounded col-span-3 mx-5'>
				<QuarterlyIncome />
			</div>
			<div className='shadow p-2 rounded col-span-3 mx-5'>
				<CustomTheme />
			</div>
		</div>
	);
}
