import React from 'react';
import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import Login from './components/Auth/Login';
import BrowserInstructions from './components/BrowserInstructions';
import Dashboard from './components/Dashboard';
import Download from './components/Download';
import LandingPage from './components/LandingPage';
import Reports from './components/Reports';
import Scans from './components/Scans';
import Settings from './components/Settings';
import SupportPage from './components/Support/SupportPage';
import Tests from './components/Tests';
import Video from './components/Video';
import CronList from './components/ak/CronList';
import { useUser } from './context/UserContext';
import MainLayout from './layout/MainLayout';
import Auth, { ACCESS_TOKEN_KEY } from './utils/auth';
import SuccessPage from './components/Support/Success';
import SupportEmailList from './components/Support/SupportEmailList';
import SupportEmailDetails from './components/Support/SupportEmailDetails';
import Screenshots from './components/ak/Screenshots';
export default function Router() {
	const { user, isLoggedIn } = useUser();
	const routes = [
		{
			path: '/',
			element: <MainLayout />,
			children: [
				{ path: '/', element: <LandingPage /> },
				{
					path: 'login',
					element: user && isLoggedIn ? <LandingPage /> : <Login signup={false} />,
				},
				{ path: 'signup', element: <Login signup={true} /> },
				{ path: 'browser-settings', element: <BrowserInstructions /> },
				{ path: 'tests', element: <Tests /> },
				{ path: 'support', element: <SupportPage /> },
				{ path: 'support/success', element: <SuccessPage /> },
			],
		},
		{
			path: '/',
			element: <MainLayout />,
			loader: async () => {
				const token = sessionStorage.getItem(ACCESS_TOKEN_KEY);
				if (token) {
					const { user } = await Auth.getUser(token);
					if (!user) return redirect('/login');
					if (user && !user.isAdmin && !user.readOnly) return redirect('/');
					if (user && user.isAdmin) return null; // allow access
					if (user && user.readOnly) return null; // allow access
				}
				return redirect('/login');
			},
			children: [
				{ path: 'report-data', element: <Reports /> },
				{ path: 'scan-data', element: <Scans /> },
				{ path: 'settings', element: <Settings /> },
				{ path: 'download/:id', element: <Download /> },
				{ path: 'dashboard', element: <Dashboard /> },
				{ path: 'video', element: <Video /> },
			],
		},
		{
			path: '/',
			element: <MainLayout />,
			loader: async () => {
				const token = sessionStorage.getItem(ACCESS_TOKEN_KEY);
				if (token) {
					const { user } = await Auth.getUser(token);
					if (!user) return redirect('/login');
					const whitelist = ['andrewkeiser@gmail.com', 'jrclayton2@gmail.com'];

					if (user && !user.isAdmin && !whitelist.includes(user.email)) {
						return redirect('/');
					} else if (user && user.isAdmin && whitelist.includes(user.email)) {
						return null; // allow through access
					}
				}
				return redirect('/login');
			},
			children: [
				{ path: 'support-emails', element: <SupportEmailList /> },
				{ path: 'support-emails/:id', element: <SupportEmailDetails /> },
			],
		},
		{
			path: '/ak',
			element: <MainLayout />,
			loader: async () => {
				const token = sessionStorage.getItem(ACCESS_TOKEN_KEY);
				if (token) {
					const { user } = await Auth.getUser(token);
					if (!user) return redirect('/login');
					if (user && !user.isAdmin && user.email !== 'andrewkeiser@gmail.com')
						return redirect('/');
					else if (user && user.isAdmin && user.email === 'andrewkeiser@gmail.com') return null; // allow through access
				}
				return redirect('/login');
			},
			children: [
				{ path: 'crons', element: <CronList /> },
				{ path: 'screenshots', element: <Screenshots /> },
			],
		},
		{ path: '*', element: <h1>Not Found</h1> },
	];
	const router = createBrowserRouter([...routes]);
	return <RouterProvider router={router} />;
}
