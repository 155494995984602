import React, { useState } from 'react';
import ConfirmModal from './Global/ConfirmModal';
import client from '../api/client';
import { useAlert } from '../context/AlertContext';
export default function DeleteTestButton({
	setTests = {},
	tests = [],
	test = {},
}) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [testIdToDelete, setTestIdToDelete] = useState(null);
	const { setAlert } = useAlert();
	const handleDelete = async (id) => {
		try {
			await client.delete(`/tests/${id}`);
			const updatedTests = tests.filter((test) => test._id !== id);
			setTests(updatedTests);
			setAlert('success', 'Test deleted successfully');
		} catch (error) {
			console.error(error);
			setAlert('error', 'An error occurred while deleting the test');
		}
	};
	const openModal = (id) => {
		setTestIdToDelete(id);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};
	return (
		<div className='inline'>
			<button
				onClick={() => openModal(test._id)}
				className='p-3 rounded shadow bg-rose-400 text-white'
			>
				Delete Test
			</button>
			<ConfirmModal
				isOpen={isModalOpen}
				onClose={closeModal}
				onConfirm={() => {
					handleDelete(testIdToDelete);
					closeModal();
				}}
			/>
		</div>
	);
}
