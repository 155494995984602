import React, { useEffect, useState } from 'react';
import client from '../../api/client';
import Spinner from '../Global/Spinner';
import Table from '../TableDynamic';
import { useAlert } from '../../context/AlertContext';
import TableDynamic from '../TableDynamic';
export default function CronList() {
	const { setAlert } = useAlert();
	const [crons, setCrons] = useState([]);
	const [loading, setLoading] = useState(false);
	const fetchCrons = async () => {
		try {
			setLoading(true);
			const { data } = await client.get('stats/crons');
			if (data && data.crons) setCrons(data.crons);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setAlert('error', 'Error fetching cron jobs');
			return console.error(error);
		}
	};

	const deleteCrons = async () => {
		try {
			setLoading(true);
			const { data } = await client.delete('stats/crons');
			if (data && data.message) setAlert('success', data.message);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setAlert('error', 'Error deleting cron jobs');
			return console.error(error);
		}
	};
	useEffect(() => {
		fetchCrons();
	}, []);
	if (loading) return <Spinner />;
	return (
		<div>
			{crons && crons.length > 0 && (
				<button
					onClick={async () => {
						await deleteCrons();
						window.location.reload();
					}}
				>
					delete all crons
				</button>
			)}
			<TableDynamic
				data={crons && crons}
				type='crons'
			/>
		</div>
	);
}
