import React, { useEffect, useState } from 'react';
import client from '../../api/client';
import TableDynamic from '../TableDynamic';
import Spinner from '../Global/Spinner';
import { useAlert } from '../../context/AlertContext';
export default function SupportEmailList() {
	const { setAlert } = useAlert();
	const [loadingData, setLoading] = useState(false);
	const [emailList, setEmailList] = useState([]);
	const fetchSupportEmailList = async () => {
		try {
			setLoading(true);
			const { data } = await client.get('/support');
			if (data && data.emails) setEmailList(data.emails);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setAlert('error', 'Error fetching stats');
			console.error(error);
		}
	};
	useEffect(() => {
		fetchSupportEmailList();
	}, []);
	if (loadingData) return <Spinner />;
	return (
		<div className='space-y-2'>
			<div>
				<h2>Please Note</h2>
				<p>
					This will only show emails not from andrewkeiser@gmail.com or
					jrclayton2@gmail.com
				</p>
			</div>
			<TableDynamic
				type='Support Emails'
				data={emailList && emailList.length > 0 ? emailList : []}
			/>
		</div>
	);
}
