import React, { useEffect } from 'react';
import { useScrape } from '../context/ScrapeContext';
import Spinner from './Global/Spinner';
import Table from './Table';
export default function Reports() {
	const { scanData, loadingData, fetchReports } = useScrape();

	useEffect(() => {
		fetchReports();
	}, []);
	if (loadingData) return <Spinner />;
	return (
		<div>
			<Table data={scanData} />
			{/* <Instructions /> */}
		</div>
	);
}
