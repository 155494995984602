import React, { useEffect } from 'react';

export default function BrowserInstructions() {
	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};
	useEffect(() => {
		scrollToTop();
	}, []);
	return (
		<div>
			<nav>
				<ul>
					<li>
						<a
							className='text-aegis-500 font-bold'
							href='#chrome'
						>
							Google Chrome
						</a>
					</li>
					<li>
						<a
							href='#firefox'
							className='text-aegis-500 font-bold'
						>
							Firefox
						</a>
					</li>
					<li>
						<a
							href='#safari'
							className='text-aegis-500 font-bold'
						>
							Safari
						</a>
					</li>
				</ul>
			</nav>
			<main className='space-y-8 '>
				<div
					id='chrome'
					className='mt-4'
				>
					<h3 className='font-bold text-2xl leading-8'>Chrome</h3>
					<ol className='space-y-3'>
						<li>Launch your Chrome web browser.</li>
						<li>
							Access the <strong>Chrome menu</strong> icon located in the upper
							right-hand corner of the window.
						</li>
						<li>
							Choose <strong>Settings</strong>.
						</li>
						<li>
							Click on <strong>Show Advanced Settings</strong> at the bottom of
							the page.
						</li>
						<li>
							Under "Privacy," click on <strong>Content Settings</strong>.
						</li>
						<li>
							Scroll down to the "Handlers" section and click the{' '}
							<strong>Manage Handlers</strong> button.
						</li>
						<li>Select your preferred default email client (e.g., Gmail).</li>
					</ol>
				</div>
				<div id='firefox'>
					<h3 className='font-bold text-2xl leading-8'>Firefox</h3>
					<ol className='space-y-3'>
						<li>Open your Firefox web browser.</li>
						<li>
							Click on the <strong>Firefox menu</strong> located in the top
							right-hand corner of the window.
						</li>
						<li>
							Choose <strong>Options</strong> (PC) or{' '}
							<strong>Preferences</strong> (Macintosh).
						</li>
						<li>
							Click the <strong>Applications</strong> subtab.
						</li>
						<li>Use the search bar to find "mailto."</li>
						<li>Click the drop-down menu under the "Action" column.</li>
						<li>Select your desired default email client (e.g., Use Gmail).</li>
						<li>
							Click <strong>OK</strong>.
						</li>
						<li>
							(Note: On a Macintosh computer, your settings will be saved
							automatically, so there's no need to click OK.)
						</li>
					</ol>
				</div>
				<div id='safari'>
					<h3 className='font-bold text-2xl leading-8'>Safari</h3>
					<ol className='space-y-3'>
						<li>Open your Safari web browser.</li>
						<li>
							Install the "Google Mailto Extension" for Safari by clicking the
							Downloads icon (an arrow pointing down) at the top right-hand side
							of the window. Click Install when prompted.
						</li>
						<li>
							In the "Options" section, select your preferred default email
							client.
						</li>
						<li>
							(Note: Once you click on an email client, it is automatically
							saved.)
						</li>
						<li>
							When you're done, restart your browser by quitting and reopening
							it.
						</li>
					</ol>
					In the "Options" section, choose your desired default email client
					(e.g., Use Gmail). (Note: Once you select an email client, it is
					automatically saved.)
				</div>
			</main>
		</div>
	);
}
