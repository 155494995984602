import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import client from '../../api/client';
import { useAlert } from '../../context/AlertContext';
import formatDate from '../../utils/formatDate';
import Spinner from '../Global/Spinner';

export default function SupportEmailDetails() {
	const { setAlert } = useAlert();
	const { id } = useParams();
	const [loadingData, setLoading] = useState(false);
	const [email, setEmail] = useState([]);
	const fetchSupportEmail = async () => {
		try {
			setLoading(true);
			const { data } = await client.get(`/support/${id}`);
			if (data && data.email) setEmail(data.email);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setAlert('error', 'Error fetching stats');
			console.error(error);
		}
	};
	useEffect(() => {
		if (id) fetchSupportEmail();
	}, [id]);

	if (loadingData) return <Spinner />;

	return (
		<div>
			<div className='px-4 sm:px-0'>
				<h3 className='text-base font-semibold leading-7 text-gray-900'>
					Email Details
				</h3>
				<p className='mt-1 max-w-2xl text-sm leading-6 text-gray-500'>
					{email.createdAt && formatDate(email.createdAt)}
				</p>
			</div>
			<div className='mt-6 border-t border-gray-100'>
				<dl className='divide-y divide-gray-100'>
					<div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
						<dt className='text-sm font-medium leading-6 text-gray-900'>
							Name
						</dt>
						<dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
							{email.name && email.name}
						</dd>
					</div>
					<div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
						<dt className='text-sm font-medium leading-6 text-gray-900'>
							Subject
						</dt>
						<dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
							{email.subject && email.subject}
						</dd>
					</div>
					<div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
						<dt className='text-sm font-medium leading-6 text-gray-900'>
							Email address
						</dt>
						<dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
							{email.email && email.email}
						</dd>
					</div>

					<div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0'>
						<dt className='text-sm font-medium leading-6 text-gray-900'>
							Message
						</dt>
						<dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
							{email.message && email.message}
						</dd>
					</div>
				</dl>
			</div>
		</div>
	);
}
