import { useState } from 'react';
import IMG from '../../logo.png';
import { Link, useNavigate } from 'react-router-dom';
import client from '../../api/client';
import { useAlert } from '../../context/AlertContext';
import { useUser } from '../../context/UserContext';
import Auth from '../../utils/auth';
import Logo from '../Global/Logo';
export default function Login({ signup = false }) {
	const [formData, setFormData] = useState({});
	const navigate = useNavigate();
	const { setIsLoggedIn, setUser } = useUser();
	const { setAlert } = useAlert();
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (signup && formData.password !== formData['confirm-password']) {
			console.error('Passwords do not match');
			setAlert('error', 'Passwords do not match');
			return;
		}

		const { data } = signup
			? await client.post('/user/signup', formData)
			: await client.post('/user/signin', formData);

		if (!data.success) {
			setAlert('error', data.error);
			console.log({ data });
		}
		if (data.success) {
			setAlert('success', data.message);
			console.log({ data });
			setUser(data.user);
			Auth.login(data.token);
			return navigate('/dashboard', { replace: true });
		}
	};
	const handleChange = (e) => {
		e.preventDefault();
		const { name, value } = e.target;
		// assigns value to name based on name
		setFormData({ ...formData, [name]: value });
	};
	return (
		<>
			<div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
				<div className='bg-white shadow-xl px-6 py-4 rounded-md sm:mx-auto sm:w-full sm:max-w-sm'>
					<div className='flex justify-center mt-12'>
						<Logo
							img={IMG}
							height='h-24'
						/>
					</div>
					<h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
						{signup ? 'Sign up for an account' : 'Sign in to your account'}
					</h2>

					<div className='bg-white mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
						<form
							className='space-y-6'
							onSubmit={handleSubmit}
						>
							{/* name */}
							{signup && (
								<div>
									<div className='flex items-center justify-between'>
										<label
											htmlFor='name'
											className='block text-sm font-medium leading-6 text-gray-900'
										>
											Name
										</label>
									</div>
									<div className='mt-2'>
										<input
											onChange={handleChange}
											id='name'
											name='name'
											type='name'
											autoComplete='current-password'
											required
											className='block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-aegis-600 sm:text-sm sm:leading-6'
										/>
									</div>
								</div>
							)}
							{/* email */}
							<div>
								<label
									htmlFor='email'
									className='block text-sm font-medium leading-6 text-gray-900'
								>
									Email address
								</label>
								<div className='mt-2'>
									<input
										onChange={handleChange}
										id='email'
										name='email'
										type='email'
										autoComplete='email'
										required
										className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-aegis-600 sm:text-sm sm:leading-6 px-2'
									/>
								</div>
							</div>

							<div>
								<div className='flex items-center justify-between'>
									<label
										htmlFor='password'
										className='block text-sm font-medium leading-6 text-gray-900'
									>
										Password
									</label>
									<div className='text-sm'>
										<a
											href='#'
											className='font-semibold text-aegis-600 hover:text-aegis-500'
										>
											Forgot password?
										</a>
									</div>
								</div>
								<div className='mt-2'>
									<input
										onChange={handleChange}
										id='password'
										name='password'
										type='password'
										autoComplete='current-password'
										required
										className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-aegis-600 sm:text-sm sm:leading-6 px-2'
									/>
								</div>
							</div>
							{/* name */}
							{signup && (
								<div>
									<div className='flex items-center justify-between'>
										<label
											htmlFor='confirm-password'
											className='block text-sm font-medium leading-6 text-gray-900'
										>
											Confirm Password
										</label>
									</div>
									<div className='mt-2'>
										<input
											onChange={handleChange}
											id='confirm-password'
											name='confirm-password'
											type='password'
											autoComplete='current-password'
											required
											className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-aegis-600 sm:text-sm sm:leading-6 px-2'
										/>
									</div>
								</div>
							)}
							<div>
								<button
									id='submit'
									type='submit'
									className='flex w-full justify-center rounded-md bg-aegis-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-aegis-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-aegis-600 mb-12'
								>
									Sign in
								</button>
							</div>
						</form>

						<p className='mt-10 text-center text-sm text-gray-500 '>
							{signup ? 'Already a member? ' : 'Not a member? '}

							<Link
								to={signup ? '/login' : '/signup'}
								className='font-semibold leading-6 text-aegis-600 hover:text-aegis-500'
							>
								{signup ? 'Login' : 'Sign up today '}
							</Link>
						</p>
					</div>
				</div>
			</div>
		</>
	);
}
