import client from '../api/client';

const getTests = async () => {
	try {
		const { data } = await client.get('/tests');

		if (data) {
			console.log({ data });
			return data;
		}
		return;
	} catch (error) {
		console.error(error);
		return;
	}
};
export default getTests;
