import React from 'react';
import PageTitle from '../Global/PageTitle';
import SupportEmailForm from './SupportEmailForm';
export default function SupportPage() {
	return (
		<div>
			<PageTitle title='Send a support email' />
			<SupportEmailForm />
		</div>
	);
}
