import { Bars3Icon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Nav from './components/Nav';

export default function MainLayout() {
	const location = useLocation();

	const [sidebarOpen, setSidebarOpen] = useState(false);

	return (
		<>
			<div>
				<Nav
					setSidebarOpen={setSidebarOpen}
					sidebarOpen={sidebarOpen}
				/>

				<div className='lg:pl-72'>
					<div className='sticky top-0 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8'>
						<button
							type='button'
							className='-m-2.5 p-2.5 text-gray-700 lg:hidden'
							onClick={() => setSidebarOpen(true)}
						>
							<span className='sr-only'>Open sidebar</span>
							<Bars3Icon
								className='h-6 w-6'
								aria-hidden='true'
							/>
						</button>
					</div>

					<main className='py-10'>
						<div className='px-4 sm:px-6 lg:px-8'>
							<Outlet />
						</div>
					</main>
				</div>
			</div>
		</>
	);
}
