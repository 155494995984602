import React from 'react';
import { Link } from 'react-router-dom';
export default function Logo({ img = null, height = 'h-10' }) {
	return (
		<Link to={`/`}>
			<img
				className={height ? `w-auto ${height}` : 'h-10 w-auto'}
				src={img}
				alt='VCS Logo'
			/>
		</Link>
	);
}
