import React, { useEffect, useState } from 'react';
import client from '../api/client';
import Spinner from './Global/Spinner';

export default function Video() {
	const [videos, setVideos] = useState([]);
	const [loading, setLoading] = useState(false);

	const getVideos = async () => {
		try {
			setLoading(true);
			const res = await client.get('/scrape/all-videos');
			const videoData = res.data.map((videoName) => ({
				title: videoName,
				size: 'Size Unknown', // You can modify this as needed
				source: `/videos/${videoName}`,
			}));
			setVideos(videoData);
			setLoading(false);
		} catch (err) {
			setLoading(false);
			console.log(err);
		}
	};

	useEffect(() => {
		getVideos();
	}, []);

	if (loading) return <Spinner />;

	return (
		<ul
			role='list'
			className='grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8'
		>
			{videos &&
				videos.map((file) => (
					<li
						key={file.source}
						className='relative'
					>
						<div className='group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100'>
							<video
								src={file.source}
								controls
								className='object-cover group-hover:opacity-75'
							/>
						</div>
						<p className='mt-2 block truncate text-sm font-medium text-gray-900'>
							{file.title}
						</p>
						<p className='block text-sm font-medium text-gray-500'>
							{file.size}
						</p>
					</li>
				))}
		</ul>
	);
}
