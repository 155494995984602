import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import client from '../api/client';
import { useAlert } from '../context/AlertContext';
export default function Download() {
	const { id } = useParams();
	const { setAlert } = useAlert();
	const exportCsv = async (id) => {
		try {
			const { data } = await client.get(`/scrape/${id}/export`, {
				responseType: 'blob',
			});
			console.log({ data });

			const url = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${new Date().toLocaleDateString()}.csv`);
			document.body.appendChild(link);
			link.click();
		} catch (error) {
			return setAlert('error', 'Failed to export data');
		}
	};

	useEffect(() => {
		exportCsv(id);
	}, [id]);

	return (
		<div className='text-center'>
			<h1>Please wait while your file downloads.</h1>
			<p>If your download does not start automatically</p>
			<button
				className='bg-aegis-500 text-white font-bold px-2 py-1 rounded shadow'
				onClick={() => exportCsv(id)}
			>
				Click here
			</button>
		</div>
	);
}
