import axios from 'axios';
const isLocalhost = window.location.hostname.includes('localhost');
const client = axios.create({
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`,
	},
	baseURL: isLocalhost
		? 'http://localhost:5056/api'
		: 'https://aegis.keiserdev.com/api',
});
export default client;
