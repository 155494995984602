import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';
import client from '../api/client';
import { useAlert } from '../context/AlertContext';
import formatDate from '../utils/formatDate';
import { Link } from 'react-router-dom';

export default function TableDynamic({ data, type = '', note = '' }) {
	const { setAlert } = useAlert();
	const [sortedData, setSortedData] = useState([]);
	const [sortConfig, setSortConfig] = useState({
		key: 'createdAt',
		direction: 'asc',
	});

	// Define an exclusion list for columns you don't want to show
	const exclusionList = [
		'_id',
		'__v',
		'dateupdated',
		'datecompleted',
		'datecreated',
		'datestarted',
		'passes',
		'fails',
		'tabledataids',
		'csv',
		'updatedat',
	];
	const linkTypes = ['support emails'];
	// Dynamically determine columns based on data
	const columns =
		data.length > 0
			? Object.keys(data[0]).filter(
					(key) => !exclusionList.includes(key.toLowerCase())
			  )
			: [];

	const sortData = (key) => {
		let direction = 'asc';
		if (sortConfig.key === key && sortConfig.direction === 'asc') {
			direction = 'desc';
		}
		setSortConfig({ key, direction });

		const sorted = [...data].sort((a, b) => {
			if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
			if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
			return 0;
		});
		setSortedData(sorted);
	};

	useEffect(() => {
		sortData(sortConfig.key);
	}, [data, sortConfig.key]);

	const exportCsv = async (id) => {
		try {
			// Make the HTTP request and destructure the JSON response directly
			const response = await client.get(`/scrape/${id}/export`);

			// Log the JSON response

			// Extract vendor name and CSV data from the JSON response
			const { vendor, csv } = response.data;
			if (vendor && csv) {
				// Convert the CSV string into a Blob for download
				const csvBlob = new Blob([csv], { type: 'text/csv' });
				const url = window.URL.createObjectURL(csvBlob);

				// Create a link element for the download
				const link = document.createElement('a');
				link.href = url;

				// Replace spaces with underscores and add the CSV extension to the vendor name for the filename
				const filename = `${vendor.replace(
					/\s+/g,
					'_'
				)}-${new Date().toLocaleString()}.csv`;
				link.setAttribute('download', filename);

				// Append the link to the body, click it, and remove it to initiate download
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

				// Clean up to avoid memory leaks
				window.URL.revokeObjectURL(url);
			} else return setAlert('warning', 'No vendor or data to export');
		} catch (error) {
			console.error('Failed to export data', error);
			// Assuming `setAlert` is a function that shows error messages to the user
			return setAlert('error', 'Failed to export data');
		}
	};

	return (
		<div className='px-4 sm:px-6 lg:px-8'>
			<div className='sm:flex sm:items-center'>
				<div className='sm:flex-auto'>
					<h1 className='text-3xl font-semibold leading-6 text-gray-900'>
						{type}
					</h1>
				</div>
			</div>
			<div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
				<div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
					<table className='min-w-full divide-y divide-gray-300'>
						<thead>
							<tr>
								{columns.map((column) => (
									<th
										key={column}
										onClick={() => sortData(column)}
										scope='col'
										className='cursor-pointer py-3.5 px-3 text-left text-sm font-semibold text-gray-900'
									>
										{column.charAt(0).toUpperCase() + column.slice(1)}
										{sortConfig.key === column &&
											sortConfig.direction === 'asc' && (
												<ChevronUpIcon
													className='h-5 w-5 inline ml-2'
													aria-hidden='true'
												/>
											)}
										{sortConfig.key === column &&
											sortConfig.direction === 'desc' && (
												<ChevronDownIcon
													className='h-5 w-5 inline ml-2'
													aria-hidden='true'
												/>
											)}
										{sortConfig.key !== column && (
											<ChevronDownIcon
												className='h-5 w-5 inline ml-2 invisible'
												aria-hidden='true'
											/>
										)}
									</th>
								))}
							</tr>
						</thead>
						<tbody className='divide-y divide-gray-200 bg-white'>
							{/* Dynamic row rendering based on columns */}
							{sortedData.map((item) => (
								<tr
									key={item._id}
									className={
										linkTypes.includes(type.toLowerCase())
											? 'hover:bg-gray-50 rounded'
											: ''
									}
								>
									{columns.map((column) => (
										<td
											key={column}
											className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 '
										>
											{linkTypes.includes(type.toLowerCase()) ? (
												<Link
													className='font-semibold text-indigo-500 '
													to={`/support-emails/${item._id}`}
												>
													{item[column]}
												</Link>
											) : column === 'createdAt' ? (
												formatDate(item[column])
											) : (
												item[column]
											)}
										</td>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}
