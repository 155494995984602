import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from './Global/Spinner';
import { useAlert } from '../context/AlertContext';
import client from '../api/client';
import { useScrape } from '../context/ScrapeContext';
import ReportAProblem from './ReportAProblem';
import BrowserInstructions from './BrowserInstructions';
export default function LandingPage({}) {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const { runScrape, loading, setLoading } = useScrape();
	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};
	useEffect(() => {
		scrollToTop();
	}, []);
	return (
		<div className='bg-white'>
			<main>
				{/* Hero section */}
				<div className='relative isolate'>
					<svg
						className='absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]'
						aria-hidden='true'
					>
						<defs>
							<pattern
								id='83fd4e5a-9d52-42fc-97b6-718e5d7ee527'
								width={200}
								height={200}
								x='50%'
								y={-1}
								patternUnits='userSpaceOnUse'
							>
								<path
									d='M100 200V.5M.5 .5H200'
									fill='none'
								/>
							</pattern>
						</defs>
						<svg
							x='50%'
							y={-1}
							className='overflow-visible fill-gray-50'
						>
							<path
								d='M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z'
								strokeWidth={0}
							/>
						</svg>
						<rect
							width='100%'
							height='100%'
							strokeWidth={0}
							fill='url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)'
						/>
					</svg>
					<div className='mx-auto max-w-7xl px-6 py-0 sm:py-0 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-0'>
						<div className='mx-auto max-w-7xl lg:mx-0 lg:flex-auto'>
							<div className='flex'></div>
							<h1 className='mt-0 max-w-7xl text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl'>
								A better way to scrape data
							</h1>
							<div className='mt-6 text-lg leading-8 text-gray-600'>
								<span className='font-semibold'>How it Works</span>
								<p className='text-lg leading-8 text-gray-600'>
									The scrape will automatically run at midnight. Each vendor
									will run independently and these processes will be staggered
									by 5 minutes. Every 10 minutes, it will check and validate the
									data. If the report did not complete or does not have valid
									data, it will automatically run the process again for that
									vendor until it has valid data. This process restarts
									everyday. That way, there should be a complete and up to date
									report from each vendor every morning ready to be downloaded.
								</p>
							</div>
							<div className='mt-6 text-lg leading-8 text-gray-600'>
								<span className='font-semibold'>Usage Instructions</span>
							</div>
							<div className='mt-6 text-lg leading-8 text-gray-600'>
								<span className='font-semibold'>Step 1:</span>
								<ul>
									<li className='text-lg leading-8 text-gray-600'>
										- Click the 'Go To Vendor Suite' button from the menu.
									</li>
									<li className='text-lg leading-8 text-gray-600'>
										- This will open a new tab with the vendor suite.
									</li>
									<li className='text-lg leading-8 text-gray-600'>
										- Login and beat the captcha.
									</li>
								</ul>
							</div>
							<div className='mt-6 text-lg leading-8 text-gray-600'>
								<span className='font-semibold'>Step 2:</span>
								<ul>
									<li className='text-lg leading-8 text-gray-600'>
										- Wait 10 minutes and check the Export Report Data page.
									</li>
									<li className='text-lg leading-8 text-gray-600'>
										- All vendors should be ready for download.
									</li>
								</ul>
							</div>

							<div className='mt-6 text-lg leading-8 text-gray-600'>
								<span className='font-semibold mr-2'>Manual Scans</span>
								<ul>
									<li className='text-lg leading-8 text-gray-600'>
										You can trigger a manual scan for any vendor from the menu.
									</li>
									<li className='text-lg leading-8 text-gray-600'>
										This may take 5-10 minutes to complete.
									</li>
									<li className='text-lg leading-8 text-gray-600'>
										This will delete the existing scan for that vendor and
										initiate a new scan.
									</li>
									<li className='text-lg leading-8 text-gray-600'>
										All scans will be automatically checked in 10 minute
										intervals and re-run until there is valid data.
									</li>
								</ul>
							</div>

							<div className='mt-6 text-lg leading-8 text-gray-600'>
								<Link
									to='/report-data'
									className='rounded-md bg-aegis-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-aegis-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-aegis-600'
								>
									View Report Data
								</Link>
							</div>
							<div className='mt-16 text-lg leading-8 text-gray-600'>
								<span className='font-semibold mr-2'>Please Note</span>
								<ul>
									<li className='text-lg leading-8 text-gray-600'>
										If a scan continues to fail there may have been a change on
										the vendor's website. This is an inherant issue with web
										scraping. Click the 'Report a Problem' button to send us a
										support email and we'll get on it right away.
									</li>
									{/* <li className='mt-3 text-lg leading-8 text-gray-600'>
										If your email doesn't open when you click the 'Report a
										Problem' you may need to change settings in your browser.
										<div className='flex justify-end'>
											<Link to='/browser-settings'>
												<button className='rounded-md  px-2.5 py-1 text-sm font-semibold text-black mr-4 hover:text-gray-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-aegis-600'>
													View Browser Instructions
												</button>
											</Link>
											<ReportAProblem />
										</div>
									</li> */}
								</ul>
							</div>
							{/* <div className='mt-10 flex items-center gap-x-6'>
								<button
									onClick={runScrape}
									className='rounded-md bg-aegis-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-aegis-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-aegis-600'
								>
									{loading ? <Spinner /> : 'Run Scrape'}
								</button>
							</div> */}
						</div>
						<div className='mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow'>
							<svg
								viewBox='0 0 366 729'
								role='img'
								className='mx-auto w-[22.875rem] max-w-full drop-shadow-xl'
							>
								<title>App screenshot</title>
								<defs>
									<clipPath id='2ade4387-9c63-4fc4-b754-10e687a0d332'>
										<rect
											width={316}
											height={684}
											rx={36}
										/>
									</clipPath>
								</defs>
								<path
									fill='#4B5563'
									d='M363.315 64.213C363.315 22.99 341.312 1 300.092 1H66.751C25.53 1 3.528 22.99 3.528 64.213v44.68l-.857.143A2 2 0 0 0 1 111.009v24.611a2 2 0 0 0 1.671 1.973l.95.158a2.26 2.26 0 0 1-.093.236v26.173c.212.1.398.296.541.643l-1.398.233A2 2 0 0 0 1 167.009v47.611a2 2 0 0 0 1.671 1.973l1.368.228c-.139.319-.314.533-.511.653v16.637c.221.104.414.313.56.689l-1.417.236A2 2 0 0 0 1 237.009v47.611a2 2 0 0 0 1.671 1.973l1.347.225c-.135.294-.302.493-.49.607v377.681c0 41.213 22 63.208 63.223 63.208h95.074c.947-.504 2.717-.843 4.745-.843l.141.001h.194l.086-.001 33.704.005c1.849.043 3.442.37 4.323.838h95.074c41.222 0 63.223-21.999 63.223-63.212v-394.63c-.259-.275-.48-.796-.63-1.47l-.011-.133 1.655-.276A2 2 0 0 0 366 266.62v-77.611a2 2 0 0 0-1.671-1.973l-1.712-.285c.148-.839.396-1.491.698-1.811V64.213Z'
								/>
								<path
									fill='#343E4E'
									d='M16 59c0-23.748 19.252-43 43-43h246c23.748 0 43 19.252 43 43v615c0 23.196-18.804 42-42 42H58c-23.196 0-42-18.804-42-42V59Z'
								/>
								<foreignObject
									width={316}
									height={684}
									transform='translate(24 24)'
									clipPath='url(#2ade4387-9c63-4fc4-b754-10e687a0d332)'
								>
									<img
										src='https://tailwindui.com/img/component-images/mobile-app-screenshot.png'
										alt=''
									/>
								</foreignObject>
							</svg>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
}
